import React from 'react'
import TextBng from '../../assets/text-bnb.svg'
import BnbMobile from '../../assets/bnb-mobile.png'

const ButtonSet = () => {
    return (
        <>
            <div className="container">
                <div className="youtube-video">
                    <div className="main-video">
                        <iframe width="100%" height="400" src="https://www.youtube.com/embed/yONALgUmAiY?controls=0" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                    </div>
                </div>
                <div className="button-set">
                    <a href="https://games.pizza-nft.com/" target="_blank">Games</a>
                    <a href="https://bscscan.com/token/0xb07905396a419b121213efe1d17cfd0ff20ae597" target="_blank">Check BSC SCAN</a>
                    <a href="https://howtobuy.pizza-nft.com/" target="_blank">How To Buy Guide</a>
                    <a href="https://www.mywallettracker.io/" target="_blank">Wallet Tracker</a>
                    <a href="https://medium.com/@PIZZANFTOFFICIAL" target="_blank">Medium Blog</a>
                    <a href="https://charity.safe-pizza.com/" target="_blank">Charity</a>
                </div>
                <div className="row deverd-div">
                    <div className="col-md-7">
                        <a href="https://bnbreward.pizza-nft.com/" target="_blank"><img src={TextBng} alt="" /></a>
                    </div>
                    <div className="col-md-5">
                        <img src={BnbMobile} alt="" className="img-responsive" />
                    </div>
                </div>
            </div>
        </>
    )
}

export default ButtonSet
