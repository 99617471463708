import React from 'react'

import Lottie from "lottie-react";
import pizzaAnimation from "../../assets/pizza/animations/animation.json";

const LottieImage = () => {
    return (
        <div className="lottie-main">
            <div className="lottie-sub">
                <div className="sub-image">
                    <Lottie animationData={pizzaAnimation}
                        onComplete
                    />
                </div>
            </div>
        </div>
    )
}

export default LottieImage
