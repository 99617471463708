import React, { useEffect, useState } from 'react'

import SocialLinks from '../components/sociallinks/SocialLinks'
import NavBar from '../components/navbar/NavBar'

import Footer from '../components/footer/Footer'
import ScrollTop from '../components/scrolltop/ScrollTop'

import SocialBar from '../components/socialbar/SocialBar'

import NftImage from '../assets/nft.svg'
import EcoImage from '../assets/echo/eco-image-01.png'
import EcoSub01 from '../assets/echo/eco-sub-image-01.png'
import EcoSub02 from '../assets/echo/eco-sub-image-02.png'
import EcoSub03 from '../assets/echo/eco-sub-image-03.png'
import EcoSub04 from '../assets/echo/eco-sub-image-04.png'
import EcoSub05 from '../assets/echo/eco-sub-image-05.png'



const PizzaNft = () => {
    return (
        <>

            <SocialLinks />
            <NavBar />
            <SocialBar />
            <div className="container-fluid">
                <div className="nftimage-container">
                    <div className="row">
                        {/* <img src={NftImage} alt="" /> */}
                        <div className="col-md-4">
                            <div className="eco-01-image">
                                <img src={EcoImage} alt="" />
                            </div>
                        </div>
                        <div className="col-md-8">
                            <div className="eco-logo-contianer">
                                <a href="https://www.pizzanft.studio/" target="_blank"><img src={EcoSub01} alt="" /></a>
                                <h3>
                                    We make Pizza NFT Marketplace. create, buy, sell & trade NFTs.
                                </h3>
                            </div>
                            <div className="eco-logo-contianer">
                                <a href="https://www.mywallettracker.com/" target="_blank"><img src={EcoSub02} alt="" /></a>
                                <h3>
                                    We created a Universal Tracker so you can track all your tokens in your Wallet mywallettracker.com | App version in IOS and Android in comingsoon.
                                </h3>
                            </div>
                            <div className="eco-logo-contianer">
                                <a href="https://www.pizzanft.biz/" target="_blank"><img src={EcoSub03} alt="" /></a>
                                <h3>
                                    Buy your family teeshirts, Hoodies and Caps for  gifts or to sport in daily 10% of proceeds will be donated to Pizza Charity Foundation.
                                </h3>
                            </div>
                            <div className="eco-logo-contianer">
                                <a href="https://charity.pizza-nft.com/" target="_blank"><img src={EcoSub04} alt="" /></a>
                                <h3>
                                    Pizza Charity Foundation direct support to supply poorest communities weekly and monthly groceries food supply.
                                </h3>
                            </div>
                            <div className="eco-logo-contianer">
                                <a href="https://games.pizza-nft.com/" target="_blank"><img src={EcoSub05} alt="" /></a>
                                <h3>
                                    We release mini arcade games and will add more new games. We will make some P2E NFT games.
                                </h3>
                            </div>
                        </div>

                    </div>
                </div>
            </div>
            <Footer />
            <ScrollTop />

        </>
    )
}

export default PizzaNft
