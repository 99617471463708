import React from 'react'
import LogoBuy from '../../assets/newlogo/logo01.png'


const Docs = () => {
    return (
        <div className="starparalax">
            <div id="title" className="buy-now-class">
                <div className="container">
                    <div className="row">
                        <div className="col-md-10 col-md-offset-1">
                            <div className="token-container"
                                data-aos="fade-up"
                                data-aos-delay="30"
                                data-aos-duration="1000"
                                data-aos-easing="ease-in-out-cubic"
                            >
                                <div className="token-logo doc-logo">
                                    <img src={LogoBuy} className="img-responsive" alt="" />
                                </div>
                            </div>
                            <div className="buy-instructions"
                                data-aos="fade-up"
                                data-aos-delay="30"
                                data-aos-duration="1000"
                                data-aos-easing="ease-in-out-cubic"
                            >
                                <h3>SafePizza Litepaper</h3>
                                <ul className="doc-list-ul">
                                    <li className="doc-list-li">
                                        <ul className="doc-list-sub-ul">
                                            <li className="white-topic">
                                                Token Name
                                            </li>
                                            <li className="white-cont">
                                                SafePizza (PIZZA)
                                            </li>
                                        </ul>
                                        <ul className="doc-list-sub-ul">
                                            <li className="white-topic">
                                                Token Type
                                            </li>
                                            <li className="white-cont">
                                                BEP-20 is a token standard on the Binance Smart Chain.
                                            </li>
                                        </ul>
                                        <ul className="doc-list-sub-ul">
                                            <li className="white-topic">
                                                Mission
                                            </li>
                                            <li className="white-cont">
                                                Our mission is to help stabilize food security worldwide through fair food distribution.
                                            </li>
                                        </ul>
                                        <ul className="doc-list-sub-ul">
                                            <li className="white-topic">
                                                Vision
                                            </li>
                                            <li className="white-cont">
                                                Our vision is to end asymmetrical distribution of food. Food security relies on availability, accessibility, and utilization. Individuals in need of food assistance must have equal access to food resources.
                                            </li>
                                            <li className="white-cont">
                                                In order for us to achieve our charity mission, we will partner with local food assistance organizations to provide them with the resources that are necessary to help bridge the gap in food disparities.
                                            </li>
                                            <li className="white-cont">
                                                SafePizza will be utilized as a form of currency that will be used to purchase pizza. Through our affiliation with companies that market pizza, our business model will enable buyers to accumulate and use SafePizza tokens for purchases.
                                            </li>
                                        </ul>
                                        <ul className="doc-list-sub-ul">
                                            <li className="white-topic">
                                                Abstract
                                            </li>
                                            <li className="white-cont">
                                                SafePizza began as a supply vs demand experiment, which later emerged into a full utility reflection token. We distribute a percentage of every buy and sell transaction back to its holders and the liquidity pool. The percentages are determined by the tax/slippage fee.
                                            </li>
                                        </ul>
                                        <ul className="doc-list-sub-ul">
                                            <li className="white-topic">
                                                Fair Launch
                                            </li>
                                            <li className="white-cont">
                                                The token creator renounced ownership and burned all liquidity pool tokens to participate in the fair launch with our community. The SafePizza smart contract was created with a total supply of 100 quadrillion tokens to maintain price stability.
                                            </li>
                                        </ul>
                                        <ul className="doc-list-sub-ul">
                                            <li className="white-topic">
                                                Token Ecosystem
                                            </li>
                                            <li className="white-cont">
                                                SafePizza token is based on the concept of Reflection, where every transaction is taxed. A percentage of the taxed amount is redistributed back to the holders. The longer you hold on to your token, the greater the reward. Another percentage is used to fuel the liquidity pool. The liquidity pool is a collection of funds locked into a smart contract and is used to enable
                                            </li>
                                        </ul>
                                        <ul className="doc-list-sub-ul">
                                            <li className="white-topic">
                                                Trading on Pancake Swap
                                            </li>
                                            <li className="white-cont">
                                                This decentralized exchange permits investors to trade BEP-20 tokens. PancakeSwap relies on user-fueled liquidity pools to enable crypto trades. There is a 4% slippage fee for each SafePizza transaction. 2% goes to liquidity and 2% is distributed back to holders.
                                            </li>
                                        </ul>
                                        <ul className="doc-list-sub-ul">
                                            <li className="white-topic">
                                                Team members
                                            </li>
                                            <li className="white-cont">
                                                The SafePizza team is composed of members from various backgrounds, including but not limited to, engineering, finance, creative design, business development, and marketing. We are a cross functional team working cohesively to develop our infrastructure and brand.
                                            </li>
                                        </ul>
                                        <ul className="doc-list-sub-ul">
                                            <li className="white-topic">
                                                Disclaimer
                                            </li>
                                            <li className="white-cont">
                                                The information in this litepaper is for educational purposes only and is not investment or financial advice. It should not be relied upon for legal or investment recommendations. Please conduct your own research before making any investment decisions. Cryptocurrency investments are volatile and high risk in nature. Do not invest more than you can afford to lose. No regulatory authority has reviewed or granted approval for any information presented in this litepaper. The opinions reflected here are subject to change without notice.
                                            </li>
                                        </ul>
                                    </li>
                                </ul>
                            </div>

                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Docs
