import React from 'react'

import MainImage from '../../assets/ast/ast-02.png'
import Instructions from '../../assets/pizza-image.jpg'

const DocBox = () => {
    return (
        <div>
            <div className="new-container">
                <div className="main-doc-box-container">
                    <div className="row">

                        <div className="col-md-12">

                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default DocBox
