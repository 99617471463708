import React from 'react'
import LogoBuy from '../../assets/newlogo/logobuy.png'

import Faq from 'react-faq-component';

const data = {
    title: "",
    rows: [
        {
            title: "1. What’s your official website?",
            content: "<h4><a href='https://safe-pizza.com' target='_blank'>www.safe-pizza.com</a> </4>"
        },
        {
            title: "2. Where can I go for updates?",
            content: "<h4>Check out our announcement group in Telegram: <a href='https://t.me/SafePizzaAnnouncementChannel' target='_blank'>t.me/SafePizzaAnnouncementChannel</a></h4> <h4>Community / 24-7 Support: Telegram: <a href='https://t.me/safepizza' target='_blank'>t.me/safepizza</a></h4>   "
        },
        {
            title: "3. Do you have an Instagram page?",
            content: "<h4><a href='https://www.instagram.com/safepizza_official/' target='_blank'>Instagram</a> </4>"
        },
        {
            title: "4. Can I find your team on LinkedIn?",
            content: "<h4>Yes, you’ll find frequent updates here. LinkedIn <a href='https://www.linkedin.com/company/safepizzaofficial/' target='_blank'>www.linkedin.com/company/safepizzaofficial/</a> </4>"
        },
        {
            title: "5. Are you on Twitter?",
            content: "<h4>Twitter: <a href='https://Twitter.com/safe_pizza/' target='_blank'>Twitter.com/safe_pizza</a> </4>"
        },
        {
            title: "6. What’s your Facebook page?",
            content: "<h4>Facebook: <a href='https://www.facebook.com/safepizza' target='_blank'>www.facebook.com/safepizza</a> </4>"
        },
        {
            title: "7. Is there a link for me to access the Discord Channel?",
            content: "<h4>Discord: <a href='https://discord.com/invite/etaeKzr4nk' target='_blank'>discord.com/invite/etaeKzr4nk</a> </4>"
        },
        {
            title: "8. Are you on Reddit?",
            content: "<h4>Reddit: <a href='https://www.reddit.com/r/SafePizzaofficial/' target='_blank'>www.reddit.com/r/SafePizzaofficial/</a> </4>"
        },
        {
            title: "9. Do you have a YouTube channel?",
            content: "<h4>YouTube: <a href='https://www.youtube.com/channel/UCXF97e7bKt18NoiB0TLWAVQ' target='_blank'>www.youtube.com/channel/UCXF97e7bKt18NoiB0TLWAVQ</a> </4>"
        },
        {
            title: "10. Can I find you guys on Coin Gecko and Coinmarketcap?",
            content: "<h4>Coingecko <a href='http://www.coingecko.com/en/coins/safepizza' target='_blank'>www.coingecko.com/en/coins/safepizza</a> </4><h4>Coinmarketcap <a href='http://coinmarketcap.com/currencies/safepizza/' target='_blank'>coinmarketcap.com/currencies/safepizza/</a> </4>"
        },
        {
            title: "11. When did this token first launch?",
            content: "<h4>Saturday, April 17, 2021</4>"
        },
        {
            title: "12. What’s the symbol for this token?",
            content: "<h4>PIZZA</4>"
        },
        {
            title: "13. Does your team have a Roadmap?",
            content: "<h4>Roadmap <a href='https://safe-pizza.com/home/#roadmap' target='_blank'>https://safe-pizza.com/home/#roadmap</a> </4>"
        },
        {
            title: "14. Where can I find some chart data? Poocoin and Dex.guru",
            content: "<h4>Poocoin <a href='https://poocoin.app/tokens/0x72eb1afddb5652e0f5c7b9a6cc1c3241348b16c6' target='_blank'>https://poocoin.app/tokens/0x72eb1afddb5652e0f5c7b9a6cc1c3241348b16c6</a> </4><h4>Dex.guru <a href='https://dex.guru/token/0x72eb1afddb5652e0f5c7b9a6cc1c3241348b16c6-bsc' target='_blank'>https://dex.guru/token/0x72eb1afddb5652e0f5c7b9a6cc1c3241348b16c6-bsc</a> </4>"
        },
        {
            title: "15. How do I know that this is not a rug pull?",
            content: "<h4>The contract was renounced by the original developer immediately upon launch.</h4><h4>We have made numerous investments into this project.<h4>Liquidity is burned.</h4><h4>We have a clear roadmap.</h4><h4>The contract is open source and accessible by anyone?</h4><h4>Our team members are present in our social media channels and their contact information is provided on our website.</h4><h4>We are currently in the process of applying for an audit.</h4><h4>Fully dedicated team.</h4>"
        },
        {
            title: "16. What is your contract Address?",
            content: "<h4>0x72eb1afddb5652e0f5c7b9a6cc1c3241348b16c6 Link: <a href='https://bscscan.com/token/0x72eb1afddb5652e0f5c7b9a6cc1c3241348b16c6' target='_blank'>https://bscscan.com/token/0x72eb1afddb5652e0f5c7b9a6cc1c3241348b16c6</a> </4>"
        },
        {
            title: "17. Who owns the contract?",
            content: "<h4> Ownership was renounced upon launch of SafePizza Token. The developers of this project burned all 100% liquidity pool tokens and participated in the fair launch with the community.</4>"
        },
        {
            title: "18. Where can I find your contract?",
            content: "<h4>Github <a href='https://github.com/SafePizzaCoin/contract/blob/main/pizza.sol' target='_blank'>https://github.com/SafePizzaCoin/contract/blob/main/pizza.sol</a> </4> <h4>Contract <a href='https://bscscan.com/token/0x72eb1afddb5652e0f5c7b9a6cc1c3241348b16c6' target='_blank'>https://bscscan.com/token/0x72eb1afddb5652e0f5c7b9a6cc1c3241348b16c6</a> </4>"
        },
        {
            title: "19. What’s the total token supply:",
            content: "<h4>100,000,000,000,000,000 (100 quadrillion)</4>"
        },
        {
            title: "20. Do I have to pay a tax for each transaction?",
            content: "<h4>There is a 4% tax/slippage on all transactions that returns 2% to the liquidity pool and 2% distributed back to holders.</4>"
        },
        {
            title: "21. How many free tokens can I expect?",
            content: "<h4>There’s no exact amount, but you receive free tokens by buying and holding.</4>"
        },
        {
            title: "22. Which version of pancake swap should I use to purchase this coin?",
            content: "<h4> Pancake Swap V1. The price on V1 is much lower than V2, you’ll receive more tokens using V1. </4>"
        },
        {
            title: "23. Why is there a price difference between the two versions of Pancake Swap?",
            content: "<h4>  The price difference is due to available liquidity. Some projects have not migrated over to V2. As aresult there is not enough liquidity on those pairs.</4>"
        },
        {
            title: "24. How can I buy SAFEPIZZA on Pancake Swap?",
            content: "<h4>How to buy <a href='https://safe-pizza.com/howtobuy' target='_blank'>https://safe-pizza.com/howtobuy</a> </4>"
        },
        {
            title: "25. What's the direct address to purchase this coin on Pancake swap V1.",
            content: "<h4>Pancakeswap V1 <a href='https://exchange.pancakeswap.finance/#/swap?outputCurrency=0x72eb1afddb5652e0f5c7b9a6cc1c3241348b16c6' target='_blank'>https://exchange.pancakeswap.finance/#/swap?outputCurrency=0x72eb1afddb5652e0f5c7b9a6cc1c3241348b16c6</a> </4>"
        },
        {
            title: "26. What Is Slippage?",
            content: "<h4>The slippage for SafePizza is 4%. <a href='https://coinmarketcap.com/alexandria/glossary/slippage' target='_blank'>https://coinmarketcap.com/alexandria/glossary/slippage</a> </4>"
        },
        {
            title: "27. Price Prediction?",
            content: "<h4>The longer you hold a token the greater the reward.</4>"
        },
        {
            title: "28. Have you gone through an audit?",
            content: "<h4>We are in the process of applying for an audit.</4>"
        },
        {
            title: "29. Where can we buy merchandise:",
            content: "<h4>ETA end of July. Shopping on our site: https://shop.safe-pizza.com</4>"
        },
    ]
}


const FaqItems = () => {



    return (
        <div className="starparalax">
            <div id="title" className="buy-now-class">
                <div className="container">
                    <div className="row">
                        <div className="col-md-10 col-md-offset-1">
                            <div className="token-container"
                                data-aos="fade-up"
                                data-aos-delay="30"
                                data-aos-duration="1000"
                                data-aos-easing="ease-in-out-cubic"
                            >
                                <div className="token-logo">
                                    <img src={LogoBuy} className="img-responsive" alt="" />
                                </div>
                            </div>
                            <div className="buy-instructions"
                                data-aos="fade-up"
                                data-aos-delay="30"
                                data-aos-duration="1000"
                                data-aos-easing="ease-in-out-cubic"
                            >
                                <h3>FAQ</h3>
                                <div className="faq-items">
                                    <Faq data={data}
                                        styles={{
                                            titleTextColor: "#ededed",
                                            rowTitleColor: "#ededed",
                                            rowContentColor: "#fff"
                                        }}
                                    />
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default FaqItems
