import React, { useState } from 'react'
import LogoBuy from '../../assets/newlogo/logobuy.png'


const Buy = () => {

    const [alertmessage, setAlertmessage] = useState("");

    const copydata = () => {
        navigator.clipboard.writeText('0x72eb1afddb5652e0f5c7b9a6cc1c3241348b16c6')
    }

    const allertdata = () => {
        setAlertmessage("Copied")
        setTimeout(() => {
            setAlertmessage("")
        }, 700)
    }

    return (
        <div className="starparalax">
            <div id="title" className="buy-now-class">
                <div className="container">
                    <div className="row">
                        <div className="col-md-10 col-md-offset-1">
                            <div className="token-container"
                                data-aos="fade-up"
                                data-aos-delay="30"
                                data-aos-duration="1000"
                                data-aos-easing="ease-in-out-cubic"
                            >
                                <div className="token-logo">
                                    <img src={LogoBuy} className="img-responsive" alt="" />
                                </div>
                                <h3>TOKEN INFO</h3>
                                <p>The Official SAFEPIZZA $PIZZA CONTRACT</p>
                                {/* <a href="https://bscscan.com/token/0x72eb1afddb5652e0f5c7b9a6cc1c3241348b16c6">0x72eb1afddb5652e0f5c7b9a6cc1c3241348b16c6</a> */}
                                <div className="copy-buttons">
                                    <h4>0x72eb1afddb5652e0f5c7b9a6cc1c3241348b16c6</h4>
                                    <span>{alertmessage}</span> <button onClick={() => { copydata(); allertdata(); }} ><i class="far fa-copy"></i> COPY</button>
                                    <a target="_blank" href="https://bscscan.com/token/0x72eb1afddb5652e0f5c7b9a6cc1c3241348b16c6"><button>GO <i class="fas fa-chevron-right"></i></button></a>
                                </div>
                            </div>
                            <div className="main-logo-cont"
                                data-aos="fade-up"
                                data-aos-delay="30"
                                data-aos-duration="1000"
                                data-aos-easing="ease-in-out-cubic"
                            >
                                <div className="button-container how-to-buy">
                                    <a target="_blank" href="https://v1exchange.pancakeswap.finance/?outputCurrency=0x72eb1afddb5652e0f5c7b9a6cc1c3241348b16c6#/swap">PANCAKE SWAP V1 ONLY, USE 4% SLIPPAGE</a>
                                </div>
                            </div>
                            <div className="buy-instructions"
                                data-aos="fade-up"
                                data-aos-delay="30"
                                data-aos-duration="1000"
                                data-aos-easing="ease-in-out-cubic"
                            >
                                <h3>HOW TO BUY SAFEPIZZA TOKEN</h3>
                                <ul>
                                    <li>DOWNLOAD The TRUSTWALLET app.</li>
                                    <li>Set Up Your Trust Wallet and Write Down Your Seed Recovery Phrase. Keep in Very Safe Location. Never, Never Share This Phrase To Anyone or Fill It In Any Forms.</li>
                                    <li>Purchase BNB (then you must Dex BNB to Smart Chain)</li>
                                    <li>ONLY use BSC (Binance Smart Chain) To Buy in Pancake Swap V1</li>
                                    <li>Go to Dapps Tab on Lower Screen (for Android); Click on Pancake Swap icon</li>
                                    <li>For IPHONE – please WATCH this VIDEO Link <a href="https://www.youtube.com/watch?v=jtIiSF_z3vU">www.youtube.com/watch?v=jtIiSF_z3vU</a></li>
                                    <li>CLICK V1(old), Then CLICK Gray Check Box and CONTINUE TO V1 Anyway (Ignore the messages about V1)</li>
                                    <li>CONNECT Upper Right or UNLOCK Wallet (done once), Then Click on Trust Wallet</li>
                                    <li>SELECT CURRENCY from BNB (Smart Chain BNB) or USDT (if you already have that)</li>
                                    <li>To: Select Currency – Paste the SafePizza Contract to ADD. 0x72eb1afddb5652e0f5c7b9a6cc1c3241348b16c6</li>
                                    <li>ADD SafePizza to the Currency</li>
                                    <li>Now Select Slippage, Click on the Wheel (Cog) </li>
                                    <li>Type 4% and X to Close Slippage Pop Up</li>
                                    <li>ENTER Amount of SAFEPIZZA You Want To Buy</li>
                                    <li>HIT SWAP</li>
                                    <li>CONFIRM</li>
                                    <li>Now Back to Main Trust Wallet Screen</li>
                                    <li>ADD Token – Upper Right Slide</li>
                                    <li>PASTE Safe Pizza Contract Address in SEARCH </li>
                                    <li>It Will Locate SafePizza and Now Slide Bar to Right So It Will Appear on Main Trust Wallet Summary of Token.</li>
                                    <li>HODL</li>
                                    <li>Or Buy More On Dips</li>
                                </ul>
                                <p>If you need Help, Go To <a href="https://t.me/safepizza">T.me/safepizza</a><br />
                                    Admin there will Assist.
                                </p>
                            </div>

                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Buy
