import React, { useEffect } from 'react'
import Coin from '../../assets/icons/coin.svg'
import Dex from '../../assets/icons/dex.svg'
import Geko from '../../assets/icons/geko.svg'
import Logo from '../../assets/IMG_5323.PNG'

const SocialLinks = () => {
    useEffect(() => {
        document.getElementById("google_translate_element").hidden = false;
    }, [])
    return (
        <div className="social-main-content">
            <div className="container">
                <div className="social-container">
                    <div className="logo-container">
                        <a href="/"><img src={Logo} alt="" /></a>
                    </div>
                    <div class="google-translate-div ">
                        <div id="google_translate_element"></div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default SocialLinks
