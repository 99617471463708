import React, { useState } from 'react'
import AOS from "aos";
import Safe from '../../assets/safe.svg'
import Automatic from '../../assets/automatic.svg'
import Coins from '../../assets/coins.svg'
import Elshape from '../../assets/v2images/el-shape-v2.png'
import nftoftheday from '../../assets/nft-of-the-day.png'
import Gabbung from '../../assets/hero-animation/gabung.png'




import "aos/dist/aos.css";

AOS.init();

const MainImages = () => {
    const [alertmessage, setAlertmessage] = useState("");

    const copydata = () => {
        navigator.clipboard.writeText('0xb07905396A419B121213efe1d17cfD0ff20aE597')
    }

    const allertdata = () => {
        setAlertmessage("Copied")
        setTimeout(() => {
            setAlertmessage("")
        }, 700)
    }
    return (
        <div className="main-image-container">
            <div className="container">
                <div className="row">
                    <div className="nft-of-the-day">
                        <a href="https://nft.pizza-nft.com/" target="_blank"><img src={nftoftheday} alt="" /></a>
                    </div>
                    <div className="gabung-container">
                        <div className="container">
                            <div className="row">
                                <div className="col-md-8">
                                    <div className="image-container"
                                        data-aos="fade-right"
                                        data-aos-delay="10"
                                        data-aos-duration="1000"
                                        data-aos-easing="ease-in-out-cubic"
                                    >
                                        <div id="animated-example" class="animated bounce">
                                            <img src={Gabbung} alt="" className="img-responsive" />
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-4">
                                    <div className="row">
                                        <div className="hero-sub">
                                            <div className="col-md-12">
                                                <div className="tokenomics">
                                                    <h2>Tokenomics</h2>
                                                    <h4>Rewards (Reflections in BNB) 3%</h4>
                                                    <h4>AUTO LP 3%</h4>
                                                    <h4>Charity 1%</h4>
                                                    <h4>BuyBacks 2%</h4>
                                                    <h4>Marketing 6%</h4>
                                                </div>
                                                <h4>Contract Address</h4>
                                                {/* <h4 className="contact-address">0xb07905396A419B121213efe1d17cfD0ff20aE597</h4> */}
                                                <h4 className="contact-address">0xb07905396A419B121213efe1d17cfD0ff20aE597</h4>
                                                <div className="copybutton">
                                                    <button onClick={() => { copydata(); allertdata(); }} ><i class="far fa-copy"></i> Copy Contract Address</button><span>{alertmessage}</span>
                                                </div>
                                                <h4>Token Name: PIZZANFT</h4>
                                                <h4>Token Symbol: $PIZZA</h4>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>
                    <div className="col-md-8">
                        <div className="main-images">
                            <h2>Never miss a drop</h2>
                            <h3>Subscribe for the latest news, drop & collectibles</h3>
                            <p>After reading the Privacy Notice, you may subscribe for our newsletter to get special offer and occasional
                                surveys delivered to your inbox. Unsubscribe at any time by clicking on the link in the email.
                            </p>
                            <form class="form-inline">
                                <a href="https://docs.google.com/forms/d/e/1FAIpQLSc7F6Xt4TL151brnUyW6cUjKwP0TDiAeW3iUQllrVnwQQ6Y2Q/viewform" target="_blank">Subscribe</a>
                            </form>
                        </div>
                    </div>
                    <div className="col-md-4">
                        <div className="el-shape">
                            <img src={Elshape} alt="" className="img-responsive" />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default MainImages
