import React, { useState } from 'react'
import Gabbung from '../../assets/hero-animation/gabung.png'
import Logo from '../../assets/logo.png'
import Charity from '../../assets/charity-fund.png'
import Marketing from '../../assets/marketing.png'
import LottieImage from '../lottieimage/LottieImage'
import Logo01 from '../../assets/newlogo/logo01.png'
import Logo02 from '../../assets/newlogo/logo02.png'
import logotext from '../../assets/logo-text.png'
import nftoftheday from '../../assets/nft-of-the-day.png'

import BuyImage from '../../assets/buy.png'


const StarParalax = () => {

    const [alertmessage, setAlertmessage] = useState("");

    const copydata = () => {
        navigator.clipboard.writeText('0x72eb1afddb5652e0f5c7b9a6cc1c3241348b16c6')
    }

    const allertdata = () => {
        setAlertmessage("Copied")
        setTimeout(() => {
            setAlertmessage("")
        }, 700)
    }

    return (
        <>

            <div className="starparalax-v2">
                {/* <div class="background">
                <img src={backgroundimg} alt="" />
            </div> */}
                <div className="hero-main-content">
                    <div className="buy-button">
                        <a href="https://pancakeswap.finance/swap?outputCurrency=0xb07905396A419B121213efe1d17cfD0ff20aE597" target="_blank"><img src={BuyImage} alt="" /></a>
                    </div>

                    <div className="hero-sub">

                        <div className="container">
                            <div className="hero-header">
                                <div className="row">
                                    <div className="col-md-12">
                                        <div className="hero-header-text">
                                            {/* <h1>SAFEPIZZA is now</h1> */}
                                            <img src={logotext} alt="" className="img-responsive" />


                                        </div>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default StarParalax
