import React, { useState } from "react";
import ReactPlayer from "react-player";

import SocialLinks from "../components/sociallinks/SocialLinks";
import NavBar from "../components/navbar/NavBar";
import Footer from "../components/footer/Footer";

import ALVA from "../assets/team/ALVA.png";
import BILAL from "../assets/team/BILAL.png";
import CARLO from "../assets/team/CARLO.png";
import FRANK from "../assets/team/FRANK.png";
import HARVEY from "../assets/team/HARVEY.png";
import HAZZ from "../assets/team/HAZZ.png";
import JACKSON from "../assets/team/JACKSON.png";
import Jazy from "../assets/team/jazy.png";
import KHANH from "../assets/team/KHANH.png";
import KURNIAWAN from "../assets/team/KURNIAWAN.png";
import MILAN from "../assets/team/MILAN.png";
import NERO from "../assets/team/NERO.png";
import NGA from "../assets/team/NGA.png";
import WIBI from "../assets/team/WIBI.png";
import RENA from "../assets/team/rena.png";

const Team = () => {
  return (
    <>
      <SocialLinks />
      <NavBar />
      <div className="team-main-container">
        <div className="container-fluid">
          <div className="team-main">
            <div className="team-sub">
              <div className="team-item">
                <div className="lottieAvatar">
                  <a
                    href="https://www.linkedin.com/in/safepizzaceo/"
                    target="_blank"
                  >
                    <img src={KHANH} alt="" className="img-responsive" />
                  </a>
                </div>
              </div>
              <div className="team-item">
                <div className="lottieAvatar">
                  <a
                    href="https://www.linkedin.com/in/jazy-stark-3948b5216/"
                    target="_blank"
                  >
                    <img src={Jazy} alt="" className="img-responsive" />
                  </a>
                </div>
              </div>
            </div>
            <div className="team-sub">
              <div className="team-item">
                <div className="lottieAvatar">
                  <img src={KURNIAWAN} alt="" className="img-responsive" />
                </div>
              </div>
              <div className="team-item">
                <div className="lottieAvatar">
                  <img src={MILAN} alt="" className="img-responsive" />
                </div>
              </div>
            </div>
            <div className="team-sub">
              <div className="team-item">
                <div className="lottieAvatar">
                  <img src={WIBI} alt="" className="img-responsive" />
                </div>
              </div>
              <div className="team-item">
                <div className="lottieAvatar">
                  <img src={RENA} alt="" className="img-responsive" />
                </div>
              </div>
            </div>
            <div className="team-sub">
              <div className="team-item">
                <div className="lottieAvatar">
                  <img src={NGA} alt="" className="img-responsive" />
                </div>
              </div>
              <div className="team-item">
                <div className="lottieAvatar">
                  <img src={BILAL} alt="" className="img-responsive" />
                </div>
              </div>
            </div>
            <div className="team-sub">
              <div className="team-item">
                <div className="lottieAvatar">
                  <img src={HARVEY} alt="" className="img-responsive" />
                </div>
              </div>
              <div className="team-item">
                <div className="lottieAvatar">
                  <img src={NERO} alt="" className="img-responsive" />
                </div>
              </div>
            </div>
            <div className="team-sub">
              <div className="team-item">
                <div className="lottieAvatar">
                  <img src={FRANK} alt="" className="img-responsive" />
                </div>
              </div>
              <div className="team-item">
                <div className="lottieAvatar">
                  <img src={JACKSON} alt="" className="img-responsive" />
                </div>
              </div>
            </div>

            <div className="team-sub">
              <div className="team-item">
                <div className="lottieAvatar">
                  <img src={CARLO} alt="" className="img-responsive" />
                </div>
              </div>

              <div className="team-item">
                <div className="lottieAvatar">
                  <img src={ALVA} alt="" className="img-responsive" />
                </div>
              </div>
            </div>

            <div className="team-sub">
              <div className="team-item">
                <div className="lottieAvatar">
                  <img src={HAZZ} alt="" className="img-responsive" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default Team;
